







/* ===== LANDING PAGE THUMBNAIL ===== */
.Home .section-top-hero {
    background: url('../Graphics/Noise.svg'), linear-gradient(130deg, var(--attaboy-pink), var(--attaboy-orange));
    width: 100%;
    height: clamp(400px, calc(100vh - 100px), 1080px);
    overflow-x: hidden;
    position: relative;
}

.Home .elipses {
    overflow: hidden;
    height: inherit;
    max-height: 1080px;
    position: absolute;
    width: 100%;
}

.Home .ultrathin-circle {
    width: 100%;
    height: 100%;
    position: relative;
}

.Home .top-title-card {
    position: absolute;
    z-index: 1;
    top: calc(45%);
    left: 5%;
    transform: translateY(-50%);
    width: 90%;
}

.Home .top-title-card * {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 1440px;
}

.Home .top-title-card :nth-child(1) {color: var(--white)}
.Home .top-title-card :nth-child(2) {color: var(--black); font-size: 24px}

@media only screen and (max-width: 1024px) {
    .Home .top-title-card :nth-child(2) {font-size: 20px}
}

@media only screen and (max-width: 768px) {
    .Home .top-title-card :nth-child(2) {font-size: 16px}
}









/* ===== SUMMARY SECTION ===== */
.Home .section-summary {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: var(--default-spacing);
}



/* ----- STATISTICS GRID ----- */
.Home .stats-grid-container {
    display: grid;
    column-gap: 80px;
    row-gap: 50px;
    grid-template-columns: repeat(2, calc(var(--default-inline-width) / 2 - 100px));
    justify-content: center;
    margin-bottom: var(--default-spacing);
}

.Home .stats-grid-container > div {
    padding-bottom: 32px;
    border-bottom: 2px solid var(--black);
}

.Home .stats-grid-container > div > h3 {color: var(--accent-gray-2)}

@media only screen and (max-width: 1024px) {
    .Home .stats-grid-container {
        column-gap: 60px;
        row-gap: 40px;
        grid-template-columns: repeat(2, calc(var(--default-inline-width) / 2 - 80px));
    }

    .Home .stats-grid-container > div {padding-bottom: 16px}
}

@media only screen and (max-width: 768px) {
    .Home .stats-grid-container {
        grid-template-columns: 60%;
        margin-bottom: 80px;
    }
}



/* ----- FEATURE CONTENT CONTAINER ----- */

.Home .feature-content-container {
    background: url('../Graphics/Noise.svg'), radial-gradient(farthest-corner at 30% 5%, var(--attaboy-orange), var(--attaboy-pink));
    width: 100%;
    aspect-ratio: 1.7 / 1;
    border-radius: 25px;
    color: var(--white);
    position: relative;
    overflow: hidden;
    margin-bottom: var(--default-spacing);
}

.Home .feature-content-container > * {
    position: absolute;
}

.Home .feature-content-container > h2 {
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
}

.Home .feature-content-container > img {
    top: 10%;
    right: 3%;
    width: 60%;
}

@media only screen and (max-width: 1024px) {
    .Home .feature-content-container > img {
        width: 55%;
    }
}

@media only screen and (max-width: 768px) {
    .Home .feature-content-container {
        border-radius: 0;
    }
}

@media only screen and (max-width: 480px) {
    .Home .feature-content-container > img {
        top: 10%;
        width: 50%;
    }
}



/* ----- ABOUT CONTENT CONTAINER ----- */
.Home .about-content-container {
    width: calc(var(--default-inline-width) - 50px);
    margin-inline: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Home .about-content-container > * {
    text-align: center;
}

.Home .about-content-container > h3 {
    margin-block: 8px 16px;
    color: var(--accent-gray-2);
    line-height: 150%;
}

.Home .about-content-container > h3 > span {color: var(--black)}
.Home .about-content-container > .connect {color: var(--black)}







/* ===== COMPONENT SECTION (EVENTS + STORIES) ===== */
.Home .section-components {
    background-color: var(--accent-gray-1);
    padding-block: 0 calc(var(--default-spacing) * 2);
}

@media only screen and (max-width: 1024px) {
    .Home .section-components {
        padding-block: var(--default-spacing) calc(var(--default-spacing) * 2);
    }
}



/* ----- TESTIMONIALS AND STORIES ----- */

.Home .scrolling-testimonials-container {
    padding-bottom: var(--default-spacing);
}

.Home .scrolling-testimonials {
    margin-top: 25px;
    overflow: auto;
    scrollbar-width: none;
    white-space: nowrap;
}

.Home .testimonial {
    background-color: var(--darkgray);
    width: 325px;
    height: 450px;
    border-radius: 15px;
    position: relative;
    margin-right: 25px;
    display: inline-block;
    white-space: wrap;
}

.Home .testimonial:first-child {
    margin-left: calc(50% - var(--default-inline-width) / 2);
}

.Home .testimonial > * {
    position: absolute;
    width: 275px;
}

.Home .testimonial > img {
    background-image: linear-gradient(#FFE2ED, #DA8585);
    width: inherit;
    height: 275px;
    left: 0;
    top: 0;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 86%);
    border-radius: 15px 15px 0 0;
}

.Home .testimonial .text {
    top: 280px;
    left: 25px;
}

.Home .testimonial .text > a {
    font-size: 12px;
    color: var(--accent-gray-4);
}

.Home .testimonial .text .title {
    font-size: 21px;
    color: var(--white);
    margin-block: 8px;
}

.Home .testimonial .text > p {
    font-size: 14px;
    color: var(--accent-gray-4);
}

@media only screen and (max-width: 1024px) {
    .Home .scrolling-testimonials {
        margin-top: 25px;
    }

    .Home .testimonial {
        width: 300px;
        height: 425px;
        margin-right: 25px;
    }

    .Home .testimonial > img {
        height: 280px;
    }

    .Home .testimonial .text {
        top: 277px;
        left: 25px;
        width: 250px;
    }

    .Home .testimonial .text > a {font-size: 10px}
    .Home .testimonial .text .title {font-size: 17px; margin-block: 8px}
    .Home .testimonial .text > p {font-size: 11px}
}

@media only screen and (max-width: 768px) {
    .Home .testimonial:first-child {
        margin-left: var(--mobile-inline-margin-spacer);
    }
}



/* ----- EVENTS ----- */
.Home .date-divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.Home .date-divider > h3 {
    color: var(--white);
    white-space: nowrap;
}

.Home .date-divider > div {
    background-color: var(--white);
    width: 100%;
    height: 2px;
    margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
    .Home .date-divider > h3 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .Home .date-divider {
        margin-top: 15px;
    }

    .Home .date-divider > h3 {
        font-size: 16px;
    }
}



/* 
    -----
    1. Box (Horizontal rectangular for Desktop)
    2. Banner (Vertical rectangular for Mobile)
    General: event
    Specific: event-box, event-banner
    -----
*/

/* --- EVENT GENERAL --- */
.Home .events {
    margin: 35px 0 0 calc(50% - var(--default-inline-width) / 2);
    width: var(--default-inline-width);
}

.Home .events svg {
    width: 15px;
    aspect-ratio: 1 / 1;
}

.Home .event {
    background-color: var(--darkgray);
    border-radius: 15px;
}

.Home .event .date .day-topper {color: var(--accent-gray-4)}
.Home .event .date .date-main {color: var(--white)}
.Home .event .text .title {color: var(--white)}

.Home .event .information > div:nth-child(1) svg {fill: #EB6F6F; margin-right: 8px}
.Home .event .information > div:nth-child(2) svg {fill: #FFFA88; margin-right: 8px}

@media only screen and (max-width: 1024px) {
    .Home .events {
        width: 100%;
        margin: 35px 0 0 0;
    }

    .Home .event:first-child {
        margin-left: calc(50% - var(--default-inline-width) / 2);
    }
}

@media only screen and (max-width: 768px) {
    .Home .events {
        margin: 25px 0 0 0;
    }

    .Home .event:first-child {
        margin-left: var(--mobile-inline-margin-spacer);
    }
}



/* --- EVENT-BOX --- */
.Home .event-box {
    width: 100%;
    aspect-ratio: 5 / 1;
    max-height: 350px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 15px;
    margin-bottom: 25px;
}

.Home .event-box .date-wrapper {
    aspect-ratio: 1 / 1.2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home .event-box .date {
    aspect-ratio: 1 / 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    flex-direction: column;
    border-image: linear-gradient(var(--attaboy-orange), var(--attaboy-pink)) 1;
    border-right-style: solid;
    border-right-width: 5px;
}

.Home .event-box .date .day-topper {
    font-size: 20px;
}

.Home .event-box .date .date-main {
    font-size: 64px;
}

.Home .event-box .text > p {
    color: var(--accent-gray-4);
    font-size: 14px;
    margin-top: 10px;
}

.Home .event-box .text .title {
    font-size: 21px;
}

.Home .event-box .content {
    height: 80%;
    margin-top: 5%;
}

.Home .event-box .information {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.Home .event-box .information > div {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.Home .event-box .information p {
    font-size: 12px;
    color: var(--accent-gray-4);
}

.Home .event-box .img-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.Home .event-box .img-wrapper > img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 15px 15px 0;
}



/* --- EVENT-BANNER --- */
.Home .event-banner {
    width: 300px;
    height: 515px;
    position: relative;
}

.Home .event-banner > * {
    position: absolute;
}

.Home .event-banner .img-wrapper,
.Home .event-banner .img-wrapper > img,
.Home .event-banner .mask {
    width: 300px;
    height: 300px;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 15px 15px 0 0;
}

.Home .event-banner .img-wrapper > img {
    width: 300px;
    height: 300px;
}

.Home .event-banner .mask {
    background-image: linear-gradient(-145deg, transparent, var(--black));
}

.Home .event-banner .date-wrapper {
    top: 215px;
    left: 25px;
    z-index: 1;
}

.Home .event-banner .date {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Home .event-banner .date .day-topper {font-size: 16px}
.Home .event-banner .date .date-main {font-size: 48px; line-height: 85%}

.Home .event-banner .content {
    top: 320px;
    left: 25px;
    display: flex;
    flex-direction: column-reverse;
    width: 250px;
}

.Home .event-banner .text .title,
.Home .event-banner .text > p,
.Home .event-banner .information,
.Home .event-banner .information > div {
    margin-top: 8px;
}

.Home .event-banner .banner-line {
    width: 130px;
    height: 5px;
    background-image: linear-gradient(90deg, var(--attaboy-pink), var(--attaboy-orange));
}

.Home .event-banner .text .title {
    font-size: 17px;
    color: var(--white);
}

.Home .event-banner .text > p {
    font-size: 11px;
    color: var(--accent-gray-4);
}

.Home .event-banner .information > div {
    display: flex;
    align-items: center;
}

.Home .event-banner .information svg {
    width: 15px;
    height: 15px;
}

.Home .event-banner .information p {
    font-size: 10px;
    color: var(--accent-gray-4);
}

@media only screen and (max-width: 1024px) {
    .Home .events {
        overflow: auto;
        scrollbar-width: none;
        white-space: nowrap;
    }

    .Home .event {
        display: inline-block;
        white-space: wrap;
        margin-right: 25px;
    }
}


.About .default-about-text {
    color: var(--accent-gray-2);
    font-size: 1.25rem;
}

.About .default-border-radius {
    border-radius: 15px;
}

:root {
    --about-spacing: 80px;
}

@media only screen and (max-width: 1024px) {
    .About .default-about-text {
        font-size: 1rem;
    }

    :root {
        --about-spacing: 60px;
    }
}

@media only screen and (max-width: 768px) {
    .About .default-about-text {
        font-size: 0.75rem;
    }

    .About .default-border-radius {
        border-radius: 10px;
    }

    :root {
        --about-spacing: 40px;
    }
}




/* ===== ABOUT CONTENT SECTION ===== */
.About .section-about-content {
    background-color: var(--white);
    padding-block: var(--about-spacing);
    /* padding: var(--about-spacing) 10% var(--about-spacing) 10%; */
}



/* ----- GRID IMAGES ----- */
.About .top-grid-images {
    display: grid;
    width: 100%;
    aspect-ratio: 1.6 / 1;
    margin-bottom: var(--about-spacing);
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    gap: 10px;
}

.About .top-grid-images > img {
    width: 100%;
    height: 100%;
}

.About .top-grid-images > img:first-child {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
}

@media only screen and (max-width: 768px) {
    .About .top-grid-images {
        grid-template: repeat(2, calc(40vw - 15px / 2)) / repeat(2, calc(40vw - 15px / 2));
    }

    .About .top-grid-images > img:first-child {
        grid-row: 1 / span 3;
        grid-column: 1 / span 2;
    }

    .About .top-grid-images > img {
        aspect-ratio: 1 / 1;
    }
}



/* ----- ABOUT CONTENT ----- */
.About .content {
    width: 95%;
    margin-inline: 2.5%;
}

.About .content-start {
    margin-bottom: var(--about-spacing);
}

.About .content-start > h2 {color: var(--black)}

.About .content-end > h3 {
    margin-bottom: 16px;
}

.About .content-end > button {color: var(--black)}



/* ----- "OUR MISSION" FEATURE ----- */
.About .our-mission-feature {
    width: 100%;
    aspect-ratio: 1.6 / 1;
    display: block;
    position: relative;
    margin-bottom: var(--about-spacing);
}

.About .our-mission-feature > * {
    position: absolute;
}

.About .our-mission-feature > img,
.About .our-mission-feature .mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.About .our-mission-feature > img {
    z-index: 0;
}

.About .our-mission-feature .mask {
    background-image: linear-gradient(110deg, var(--black), transparent 60%);
    z-index: 1;
}

.About .our-mission-feature .text {
    z-index: 2;
    width: 90%;
    left: 7%;
    top: 10%;
}

.About .our-mission-feature .text > h2 {color: var(--white)}
.About .our-mission-feature .text > h3 {
    color: var(--accent-gray-4);
    width: 40%;
}

@media only screen and (max-width: 600px) {
    .About .our-mission-feature {
        aspect-ratio: unset;
    }

    .About .our-mission-feature > * {
        position: static;
    }

    .About .our-mission-feature .mask {
        display: none;
    }

    .About .our-mission-feature > img {
        aspect-ratio: 1.2 / 1;
    }

    .About .our-mission-feature .text {
        width: calc(100% - 64px);
        padding: 4px 16px;
        margin: 12px 16px;
        border-image: linear-gradient(0deg, var(--attaboy-orange), var(--attaboy-pink)) 1;
        border-left-style: solid;
        border-left-width: 5px;
    }

    .About .our-mission-feature .text > h2 {
        color: var(--black);
    }
    .About .our-mission-feature .text > h3 {
        color: var(--accent-gray-2);
        width: 100%;
    }
}





/* ===== "MEET THE TEAM" SECTION ===== */
.About .section-about-team {
    background-color: var(--accent-gray-1);
    padding-block: 0 calc(var(--default-spacing) * 2);
}

.About .section-about-team .about-team h2 {
    margin-bottom: 25px;
}

@media only screen and (max-width: 1024px) {
    .About .section-about-team {
        padding-block: var(--default-spacing) calc(var(--default-spacing) * 2);
    }

    .About .section-about-team .about-team h2 {
        margin-bottom: 25px;
    }
}



/* ----- PORTFOLIO CARDS ----- */
.About .portfolio-card {
    width: 100%;
    height: auto;
    background-color: var(--darkgray);
    margin-bottom: 25px;
}

.About .portfolio-card .background-banner-color {
    display: none;
}

.About .portfolio-card .card-content {
    display: flex;
    padding: 50px;
    justify-content: space-between;
    position: relative;
    color: var(--white);
}

.About .portfolio-card img {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    height: 200px;
    border: 5px solid var(--white);
}

.About .portfolio-card .text {
    width: 100%;
    margin-left: 25px;
    padding-left: 25px;
    border-image: linear-gradient(0deg, var(--attaboy-orange), var(--attaboy-pink)) 1;
    border-left-style: solid;
    border-left-width: 5px;
}

.About .portfolio-card svg {
    width: 35px;
    height: 35px;
    fill: var(--white);
    /* margin-inline: 4px; */
}

.About .portfolio-card .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.About .portfolio-card .social-media {
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    justify-content: end;
}

.About .portfolio-card .text .name {
    font-size: 44px;
    font-weight: 600;
}

.About .portfolio-card .text .title {
    font-size: 28px;
    color: var(--accent-gray-4);
    margin-bottom: 12px;
    font-weight: 600;
}

.About .portfolio-card .text .description p {
    font-size: 16px;
    color: var(--accent-gray-4);
}



/* ----- CARD UNDER 1024px ----- */
@media only screen and (max-width: 1024px) {
    .About .portfolio-card .card-content {
        flex-wrap: wrap;
        justify-content: unset;
    }

    .About .portfolio-card .img-wrapper {
        padding-bottom: 20px;
        border-image: linear-gradient(-90deg, var(--attaboy-orange), var(--attaboy-pink)) 1;
        border-bottom-style: solid;
        border-bottom-width: 5px;
        width: min-content;
    }

    .About .portfolio-card img {
        height: 150px;
        border: 4px solid var(--white);
    }

    .About .portfolio-card .header-container {
        display: flex;
    }

    .About .portfolio-card .header {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        margin: 0 0 20px 25px;
    }

    .About .portfolio-card .text {
        border: none;
        margin-left: 0;
        padding-left: 0;
    }

    .About .portfolio-card .header .name {
        font-size: 44px;
        font-weight: 600;
    }

    .About .portfolio-card .header .title {
        font-size: 28px;
        color: var(--accent-gray-4);
        font-weight: 600;
    }

    .About .portfolio-card .text .description {
        margin-block: 20px;
    }

    .About .portfolio-card .social-media {
        justify-content: start;
    }
}

@media only screen and (max-width: 768px) {
    .About .portfolio-card {
        position: relative;
    }

    .About .portfolio-card .background-banner-color {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 95px;
        background-image: linear-gradient(45deg, var(--attaboy-pink), var(--attaboy-orange));
        border-radius: 10px 10px 0 0;
    }

    .About .portfolio-card .card-content {
        padding: 25px;
    }

    .About .portfolio-card .img-wrapper {
        padding-bottom: 8px;
        margin-bottom: 8px;
    }

    .About .portfolio-card img {
        height: 100px;
        border: 3px solid var(--white);
    }

    .About .portfolio-card .header-container {
        display: block;
    }

    .About .portfolio-card .header {
        margin: 0;
    }

    .About .portfolio-card .header .name {
        font-size: 24px;
    }

    .About .portfolio-card .header .title {
        font-size: 16px;
    }

    .About .portfolio-card .text .description {
        margin-block: 8px;
    }

    .About .portfolio-card .text .description p {
        font-size: 14px;
    }

    .About .portfolio-card svg {
        width: 30px;
        height: 30px;
    }
}
.connect {
    aspect-ratio: 3 / 1;
    transition: 0.3s;
    background-color: var(--attaboy-pink-dimmed);
    border: 2px solid var(--attaboy-pink);
    color: var(--white);
}
.connect:hover {
    background-color: var(--attaboy-pink);
    color: var(--black);
}

.connect-button-small {
    width: 140px;
    border-radius: 25px;
    font-size: 20px;
}

.connect-button {
    /* width: clamp(66px, 8vw, 147px); */
    width: 150px;
    border-radius: 80px;
    /* font-size: clamp(0.66rem, 1.2vw, 1.33rem); */
    font-size: 1.5rem;
}

@media only screen and (max-width: 1024px) {
    .connect-button {
        width: 110px;
        font-size: 1.0625rem;
    }
}

@media only screen and (max-width: 768px) {
    .connect-button {
        width: 80px;
        font-size: 0.75rem;
    }
}





.inline-content {
    width: var(--default-inline-width);
    margin-inline-start: auto;
    margin-inline-end: auto;
}

@media only screen and (max-width: 768px) {
    .mobile-inline-margin-spacer {
        margin-inline: var(--mobile-inline-margin-spacer) !important;
        width: 80%;
    }
}





.cap-end-margin {
    margin-inline: 100px;
    width: calc(100% - 200px);
}

@media only screen and (max-width: 1024px) {
    .cap-end-margin {
        margin-inline: 50px;
        width: calc(100vw - 100px);
    }
}

@media only screen and (max-width: 768px) {
    .cap-end-margin {
        width: 90%;
        margin-inline: 5%;
    }
}





.gradient-header {
    background-image: linear-gradient(90deg, var(--attaboy-orange), var(--attaboy-pink));
    background-clip: text;
    white-space: nowrap;
    -webkit-text-fill-color: transparent;
    width: min-content;
}






.nav-links a {
    font-weight: 500;
    transition: 0.3s;
    border: none;
}

.nav-links a:hover {
    font-weight: 600;
    transform: translateY(-2px);
    border-bottom: 3px solid var(--white);
}



@media only screen and (min-width: 1024px) {
    .rounded-tab-plugin {
        width: 100%;
        margin-top: var(--default-spacing);
    }
    
    .special-tab-plugin {
        margin: 0;
        padding-block: 0 var(--default-spacing) !important;
    }
    
    .remote-tab-spacer {
        padding-top: 40px;
    }
    
    .rounded-tab-plugin .rounded-tab {
        position: relative;
        width: 100%;
        overflow: visible;
        background-color: inherit;
    }
    
    .rounded-tab-plugin .rounded-tab .tab {
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: 80px;
        border-radius: 80px 80px 0 0;
        background-color: inherit;
        z-index: 2;
    }
}







.wordmark-link-wrapper {
    height: 55px;
}
.wordmark {
    width: 130px;
}






header {
    background-color: var(--darkgray);
    height: 100px;
    width: 100%;
    /* max-width: 2000px; */
}

header .nav-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin-inline-start: auto;
    margin-inline-end: auto;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    color: var(--white);
}

nav > a {
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    nav {
        display: none;
    }
}





.accessibility-skip {
    position: absolute;
    left: 0%;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.accessibility-skip:focus {
    position: static;
    left: auto;
    width: auto;
    height: auto;
}





.mobile-menu {
    display: none;
}

.mobile-menu .bars {
    stroke: var(--white);
}

.mobile-menu .x {
    display: none;
    stroke: var(--white);
    position: absolute;
    top: 25px;
    right: 5%;
    z-index: 10;
}

.mobile-menu .menu {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100px;
    z-index: 9;
    background-color: var(--darkgray);
    top: 0;
    left: 0;
}

.open .bars {
    display: none;
}

.open .x {
    display: block;
}

.open .menu {
    display: block;
    height: 100vh;
}

.open ul {
    position: absolute;
    top: 100px;
    left: calc(5% + 12px);
    color: var(--white);
}

.open li {padding-bottom: 10px}
.open a {font-size: 28px}

@media only screen and (max-width: 768px) {
    .mobile-menu {
        display: block;
    }

    .Page:has(.open) {
        height: 100vh;
        overflow-y: hidden;
    }
}





footer {
    width: 100%;
    height: auto;
    background-color: var(--darkgray);
}

footer .cta-hero-card {
    width: var(--default-inline-width);
    aspect-ratio: 3 / 1;
    min-height: 200px;
    background-image: linear-gradient(var(--attaboy-pink), var(--attaboy-orange));
    position: relative;
    margin-inline-start: auto;
    margin-inline-end: auto;
    transform: translateY(-80px);
}

footer .cta-hero-card,
footer .mask,
footer .cta-hero-card img {
    border-radius: 25px;
}

footer .cta-hero-card img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

footer .cta-hero-card > * {
    position: absolute;
}

footer .cta-hero-card .text {
    left: 6%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: var(--white);
}

footer .cta-hero-card h2 {
    font-size: 3rem;
    margin-bottom: 16px
}

footer .mask {
    background-image: linear-gradient(-110deg, transparent, var(--black) 75%);
    width: 100%;
    height: 100%;
}

footer .main {
    height: 130px;
    padding-bottom: 25px;
    max-width: 1440px;
    margin-inline-start: auto;
    margin-inline-end: auto;
}

footer svg {
    width: 35px;
    height: 35px;
    margin-inline: 4px;
}

footer .above-divider {
    border-bottom: 2px solid var(--white);
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .below-divider {
    margin-top: 16px;
    color: var(--accent-gray-3);
}

footer .nav-container {
    display: flex;
    align-items: center;
}

footer .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    color: var(--white);
}

footer .social-media {
    margin-left: 32px;
    display: inline;
    height: 35px;
    fill: var(--white);
}

@media only screen and (max-width: 1024px) {
    footer .nav {
        display: none;
    }

    footer .cta-hero-card h2 {
        font-size: 2.1rem;
        margin-bottom: 12px;
    }

    footer .cta-hero-card,
    footer .mask,
    footer .cta-hero-card img {
        border-radius: 20px;
    }
}

@media only screen and (max-width: 768px) {
    footer {
        height: auto;
    }

    footer .cta-hero-card {
        width: 100%;
        margin-left: 0;
    }

    footer .cta-hero-card,
    footer .mask,
    footer .cta-hero-card img {
        border-radius: 0;
    }

    footer .below-divider p {
        font-size: 14px;
    }

    footer .social-media {
        width: calc(35px * 4 + 35px);
    }

    footer .cta-hero-card h2 {
        font-size: 1.9rem;
        margin-bottom: 8px;
    }
}






/* ===== CONNECT POPUP ===== */
.connect-popup-wrapper [name="button"] {
    font-size: 1.4rem;
    color: var(--white);
    border-radius: 100px;
    width: 130px;
    height: 50px;
    text-align: center;
    margin-top: 25px;
    transition: 0.3s;
    cursor: pointer;
    caret-color: transparent;
}

@media only screen and (max-width: 1024px) {
    .connect-popup-wrapper [name="button"] {
        font-size: 1rem;
        border-radius: 100px;
        width: 100px;
        height: 40px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .connect-popup-wrapper [name="button"] {
        font-size: 0.9rem;
        border-radius: 100px;
        width: 80px;
        height: 35px;
        margin-top: 15px;
    }
}





.connect-popup-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9;
}

.connect-popup {
    background-color: var(--darkgray);
    /* position: absolute; */
    z-index: 10;
    /* top: 5%;
    left: 50%; */
    /* transform: translateX(-50%); */
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-top: 5vh;
    border-radius: 50px;
    width: min-content;
    min-height: 400px;
    display: block;
}

.connect-popup.confirmation {
    display: none;
}

.connect-popup-content {
    width: 700px;
    height: calc(90vh - 160px);
    max-height: 680px;
    padding: 80px 110px;
    color: var(--white);
    overflow: auto;
    scrollbar-width: none;
}

.connect-popup-content select option[disabled]:first-child {
	display: none;
}

.connect-popup-content .header {
    border-bottom: 2px solid var(--white);
    padding-bottom: 25px;
    margin-bottom: 50px;
}

.connect-popup-content .entries {
    display: grid;
    grid-template: auto / repeat(2, 300px);
    column-gap: 100px;
    row-gap: 50px;
}

.connect-popup-content .entry {
    border-bottom: 2px solid var(--accent-gray-3);
    display: flex;
    flex-direction: column;
    height: 75px;
}

.connect-popup-content .entry:last-child {
    grid-area: auto / span 2;
}

.connect-popup-content .entry label {
    font-size: 1.2rem;
    color: var(--accent-gray-4);
}

.connect-popup-content .entry input {
    font-size: 1.8rem;
    margin-top: 10px;
    color: var(--white);
}

.connect-popup-content .entry input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.connect-popup-content .entry select {
    font-size: 1.8rem;
    color: var(--white);
    margin-top: 10px;
}

.connect-popup-content .entry option {
    color: var(--black);
    background-color: var(--accent-gray-5);
    font-size: 1.4rem;
}

.connect-popup-content [name="button"]:hover {
    color: var(--black);
}

.connect-popup-content [value="Submit"] {
    background-color: var(--attaboy-pink-dimmed);
    border: 2px solid var(--attaboy-pink);
}

.connect-popup-content [value="Submit"]:hover {
    background-color: var(--attaboy-pink);
}

.connect-popup-content [value="Cancel"] {
    background-color: var(--accent-gray-3-dimmed);
    border: 2px solid var(--accent-gray-3);
    margin-left: 25px;
}

.connect-popup-content [value="Cancel"]:hover {
    background-color: var(--accent-gray-3);
}

@media only screen and (max-width: 1024px) {
    .connect-popup {
        border-radius: 25px;
    }

    .connect-popup-content {
        width: 540px;
        height: calc(90vh - 120px);
        max-height: 560px;
        padding: 60px 80px;
    }

    .connect-popup-content .entries {
        grid-template: auto / repeat(2, 235px);
        column-gap: 70px;
        row-gap: 30px;
    }

    .connect-popup-content .entry {
        height: 60px;
    }

    .connect-popup-content .entry label {
        font-size: 1rem;
    }
    
    .connect-popup-content .entry input {
        font-size: 1.3rem;
        margin-top: 10px;
    }
    
    .connect-popup-content .entry select {
        font-size: 1.3rem;
        margin-top: 10px;
    }
    
    .connect-popup-content .entry option {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .connect-popup {
        border-radius: 15px;
    }

    .connect-popup-content .header {
        padding-bottom: 15px;
        margin-bottom: 25px;
    }

    .connect-popup-content .header h2 {
        font-size: 2.3rem;
    }

    .connect-popup-content .header h3 {
        font-size: 1rem;
    }

    .connect-popup-content {
        width: 400px;
        height: calc(90vh - 100px);
        max-height: 780px;
        padding: 50px;
    }

    .connect-popup-content .entries {
        grid-template: auto / 400px;
        row-gap: 30px;
    }

    .connect-popup-content .entry:last-child {
        grid-area: unset;
    }

    .connect-popup-content .entry label {
        font-size: 0.75rem;
    }
    
    .connect-popup-content .entry input {
        font-size: 1rem;
        margin-top: 8px;
    }
    
    .connect-popup-content .entry select {
        font-size: 1rem;
        margin-top: 8px;
    }
    
    .connect-popup-content .entry option {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 580px) {
    .connect-popup-content .header h2 {
        font-size: 2rem;
    }

    .connect-popup-content .header h3 {
        font-size: 0.75rem;
    }

    .connect-popup-content {
        width: 250px;
        height: calc(90vh - 80px);
        max-height: 700px;
        padding: 40px;
    }

    .connect-popup-content .entries {
        grid-template: auto / 250px;
    }

    .connect-popup-content .entry {
        height: 50px;
    }
}





/* ===== CONNECT POPUP - STATUS SCREENS ===== */
.connection-status-panel {
    background-color: var(--darkgray);
    z-index: 10;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-top: 5vh;
    border-radius: 50px;
    width: min-content;
    display: block;
}

.connection-status-panel.actions {
    display: none;
}

.connection-status-panel-content {
    padding: 80px;
    display: grid;
    grid-template: 200px / 200px auto;
    gap: 50px;
}

.connection-status-panel-content svg {
    width: 200px;
    height: 200px;
    fill: transparent;
    visibility: hidden;
}

.connection-status-panel-content .status-mark {
    position: relative;
}

.connection-status-panel-content .status-mark > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.connection-status-panel-content .status-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--white);
}

.connection-status-panel-content .status-text .exit-button {
    background-color: var(--accent-gray-3-dimmed);
    border: 2px solid var(--accent-gray-3);
}

.connection-status-panel-content .status-text .exit-button:hover {
    background-color: var(--accent-gray-3);
}

.connection-status-panel-content .status-mark .load {visibility: visible}
.connection-status-panel-content .fail .load {visibility: hidden}
.connection-status-panel-content .success .load {visibility: hidden}

.connection-status-panel-content .fail .x {visibility: visible}
.connection-status-panel-content .fail .check {visibility: hidden}
.connection-status-panel-content .success .x {visibility: hidden}
.connection-status-panel-content .success .check {visibility: visible}

@media only screen and (max-width: 1024px) {
    .connection-status-panel {
        border-radius: 40px;
    }

    .connection-status-panel-content {
        padding: 60px;
        grid-template: 150px / 150px auto;
        gap: 40px;
    }

    .connection-status-panel-content svg {
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-width: 768px) {
    .connection-status-panel {
        border-radius: 30px;
    }

    .connection-status-panel-content {
        padding: 40px;
        grid-template: 150px auto / auto;
        gap: 30px;
    }
}

:root {
  --white: #FFFFFF;
  --black: #000000;
  --darkgray: #1B1B1B;

  --background: #1B1B1B;
  --attaboy-pink: #EA8EAF;
  --attaboy-orange: #F9A496;
  --attaboy-blue: #8093F1;

  --attaboy-pink-dimmed: rgba(234, 142, 175, 0.5);
  --accent-gray-3-dimmed: #a1a1a180;

  --accent-gray-1: #323232;
  --accent-gray-2: #5C5C5C;
  --accent-gray-3: #A1A1A1;
  --accent-gray-4: #D2D2D2;
  --accent-gray-5: #ECECEC;


  --default-inline-width: 980px;
  --mobile-inline-margin-spacer: 10%;
  --viewport-minimum-width: 360px;
  --default-spacing: 80px;
}





.App {
  width: 100%;
  /* max-width: 1920px; */
  min-width: 360px;
  /* margin-left: 50%; */
  /* transform: translateX(-50%); */
}

.App > div {
  text-align: center;
}


.strong {
  font-weight: 700;
}

.hide {
  display: none;
}


/* || Extra Large || */
h1 {
  /* font-size: clamp(2.5rem, 4vw, 5rem); */
  font-size: 3rem;
  font-weight: 600;
}
/* || Large || */
h2 {
  /* font-size: clamp(1.33rem, 3.33vw, 4rem); */
  font-size: 3.6rem;
  font-weight: 600;
}
/* || Medium || */
h3 {
  /* font-size: clamp(0.55rem, 1.33vw, 1.66rem); */
  font-size: 1.5rem;
  font-weight: 500;
}
/* || Small || */
h4 {
  /* font-size: clamp(0.5rem, 1vw, 1.5rem); */
  font-size: 1.5rem;
  font-weight: 500;
}
/* || Extra Small || */
h5 {
  /* font-size: clamp(0.33rem, 0.5vw, 1rem); */
  font-size: 1.2rem;
  font-weight: 500;
}

p {
  font-size: 1.125rem;
  font-weight: 500;
}

button {
  outline: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

input {
  outline: 0;
  border: 0;
  background-color: unset;
  box-sizing: border-box;
  cursor: text;
}

a {
  font-size: 20px;
  text-decoration: none;
  color: unset;
  cursor: pointer;
}

img {
  outline: 0;
  border: 0;
  object-fit: cover;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

select {
  background-color: unset;
  outline: 0;
  border: 0;
}

option {
  outline: 0;
  border: 0;
  background-color: unset;
}

svg {
  fill: var(--white);
}



@media only screen and (max-width: 1240px) {
  :root {
    --default-inline-width: 900px;
  }
}



@media only screen and (max-width: 1024px) {
  :root {
    --default-inline-width: 75vw;
  }



  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2.8rem;
  }
  
  h3 {
    font-size: 1.0625rem;
  }
}



@media only screen and (max-width: 768px) {
  :root {
    --default-inline-width: clamp(var(--viewport-minimum-width), 100vw, 768px);
  }



  h1 {
    font-size: 2.3rem;
  }

  h2 {
    font-size: 2.2rem;
  }
  
  h3 {
    font-size: 0.9rem;
  }
}



@media only screen and (max-width: 580px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }
  
  h3 {
    font-size: 0.75rem;
  }
}